<div *ngIf="member">
  <h2>KASSA / Bestelling - {{ member.name }}</h2>
  <h2>
    Saldo voor bestelling:
    <span *ngIf="member.balance < 0" style="color: red;">{{ member.balance | currency:"EUR" }}</span>
    <span *ngIf="member.balance >= 0">{{ member.balance | currency:"EUR" }}</span>
    <span style="font-size: 14px;"><br />Let op! Saldo kan achter lopen. <ng-container *ngIf="member.lastOrder">Laatste update was op {{ member.lastOrder | date:'EEEE d MMM yyyy' }} om {{ member.lastOrder | date:'HH:mm' }}</ng-container></span>
  </h2>
</div>

<div class="cart-item" *ngFor="let item of items">
  <span>{{ item.name }}</span>
  <span>{{ item.quantity }}</span>
</div>

<div class="fixed-button">
  <button class="button cancel-button" (click)="ClearCart()">ANNULEREN</button>
  <button class="button send-button" (click)="SendCart()">VERSTUREN</button>
</div>

