import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthAdminService {
  isAdminLoggedIn = false;

  // store the URL so we can redirect after logging in
  redirectUrl: string;

  getAdminKey(): string {
    return localStorage.getItem('AuthKeyAdmin');
  }

  loginAdmin(authKey): Observable<boolean> {
    return of(true).pipe(
      delay(1000),
      tap(val => {
        localStorage.setItem('AuthKeyAdmin', authKey);
        this.isAdminLoggedIn = true;
      })
    );
  }

  logoutAdmin(): void {
    localStorage.removeItem('AuthKeyAdmin');
    this.isAdminLoggedIn = false;
  }
}
