import { Component, EventEmitter, OnInit } from '@angular/core';
import { Member } from 'src/app/interfaces/member';
import { AdminApiService } from 'src/app/services/admin-api.service';
import { LoadingService } from 'src/app/services/loading.service';
import { OrderDetail } from 'src/app/interfaces/order-detail';

@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.css']
})
export class BalanceComponent implements OnInit {
  members: Member[];
  orders: OrderDetail[];
  year = new Date().getFullYear();
  years = Array.from({ length: this.year - 2019 }, (v, k) => this.year - k);
  months: string[] = ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sept', 'okt', 'nov', 'dec'];

  yearMonthSelected: EventEmitter<{ year: number | null , month: number | null }> = new EventEmitter<{ year: number , month: number }>();
  monthSelectedValue: number | null = null;
  yearSelectedValue: number | null = null;

  constructor(private adminApiService: AdminApiService,
              private loading: LoadingService) {
  }

  ngOnInit() {
    this.updateMembers();
    this.updateOrders();

    this.yearMonthSelected.subscribe(next => {
      this.yearSelectedValue = next.year;
      this.monthSelectedValue = next.month;
      this.updateMembersByYearMonth(next.year, next.month);
    });
  }

  updateMembers() {
    this.loading.show();
    this.members = null;
    this.adminApiService.GetMembers()
      .subscribe(data => {
        this.members = data;
        this.loading.hide();
      });
  }

  updateMembersByYearMonth(year: number, month: number | null = null) {
    if (!year) {
      return;
    }
    this.loading.show();
    this.members = null;
    this.adminApiService.GetMembersByYearMonth(year, month)
      .subscribe(data => {
        this.members = data;
        this.loading.hide();
      });
  }

  updateOrders() {
    this.adminApiService.GetOrders()
      .subscribe(data => this.orders = data);
  }

  selectYear(year: number) {
    this.yearSelectedValue = year;
    this.monthSelectedValue = null;
    this.yearMonthSelected.emit({ year, month: null });
  }

  selectMonth(month: number) {
    this.monthSelectedValue = month;
    this.yearMonthSelected.emit({ year: this.yearSelectedValue, month });
  }
}
