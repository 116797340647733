import { Component, OnInit } from '@angular/core';
import { AdminApiService } from 'src/app/services/admin-api.service';
import { Contribution } from 'src/app/interfaces/contribution';

@Component({
  selector: 'app-contribution',
  templateUrl: './contribution.component.html',
  styleUrl: './contribution.component.css'
})
export class ContributionComponent implements OnInit {
  public contributions: Contribution[];
  public contributionsFiltered: Contribution[];
  searchName = '';

  private toggleNotPaid = false;

  constructor(private adminApiService: AdminApiService) {
  }

  ngOnInit() {
    this.adminApiService.GetContribution().subscribe(data => {
      this.contributions = data;
      this.contributionsFiltered = data;
    });
  }

  ToggleNotPaid(): void {
    this.toggleNotPaid = !this.toggleNotPaid;
    if (this.toggleNotPaid) {
      this.contributionsFiltered = this.contributions.filter(c => c.isPaid === false);
    } else {
      this.contributionsFiltered = this.contributions;
    }
  }

  setPaid(id: number) {
    this.adminApiService.GetContributionPaid(id).subscribe(() => {
      this.adminApiService.GetContribution().subscribe(data => this.contributions = data);
    });
  }

  sendEmail(id: number): void {
    const contribution = this.contributions.find(c => c.id === id);
    if (!contribution)  {
      return;
    }
    this.adminApiService.SendContributionEmail(contribution.email).subscribe(() => {
      this.adminApiService.GetContribution().subscribe(data => this.contributions = data);
    });
  }
}
