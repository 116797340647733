<div class="membersearch" style="text-align: center">
  <input class="search search-name-input" type="text" name="search" [(ngModel)]="searchName" autocomplete="off" placeholder="Zoek voor een naam">
</div>

<div>
  <button (click)="ToggleNotPaid()">NIET BETAALD</button>
  <table id="balance-table">
    <tr>
      <th>Email</th>
      <th>Naam</th>
      <th>Lid</th>
      <th>Stalling</th>
      <th>Totaal</th>
      <th>Betaald</th>
      <th></th>
    </tr>
    <tr *ngFor="let c of contributionsFiltered | filter: searchName">
      <td>{{ c.email }}</td>
      <td>{{ c.name }}</td>
      <td>{{ c.amountMember | currency:'EUR' }}</td>
      <td>{{ c.amountStorage | currency:'EUR' }}</td>
      <td>{{ c.amount | currency:'EUR' }}</td>
      <td>{{ c.isPaid ? 'Ja' : 'Nee' }}</td>
      <td>
        <ng-container *ngIf="!c.isPaid">
        <button (click)="sendEmail(c.id)">Herinnering</button>&nbsp;
        <button (click)="setPaid(c.id)">Betaald</button>
        </ng-container>
      </td>
    </tr>
  </table>
</div>
