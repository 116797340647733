import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;
    if (searchText == "") return items;

    searchText = searchText.toLowerCase();

    return items.filter(it => {
      if (typeof it === 'string') {
        return it.toLowerCase().includes(searchText);
      } else if (typeof it === 'object') {
        for (let key in Object.keys(it)) {
          const keyName = Object.keys(it)[key];
          if (typeof it[keyName] === 'string'){
            if (it[keyName].toLowerCase().includes(searchText)) {
              return true;
            }
          }
        }
      }
    });
  }
}
