import { Component, OnInit } from '@angular/core';
import { AdminApiService } from '../../services/admin-api.service';
import { Productprice } from '../../interfaces/productprice';

@Component({
  selector: 'app-productprices',
  templateUrl: './productprices.component.html',
  styleUrls: ['./productprices.component.css']
})
export class ProductpricesComponent implements OnInit {
  productPrices: Productprice[];

  constructor(private adminApiService: AdminApiService) { }

  ngOnInit() {
    this.adminApiService.GetProductPrices().subscribe(next => {
      this.productPrices = next;
    });
  }

}
