import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn = false;

  // store the URL so we can redirect after logging in
  redirectUrl: string;

  getKey(): string {
    return localStorage.getItem('AuthKey');
  }

  login(authKey): Observable<boolean> {
    return of(true).pipe(
      delay(1000),
      tap(val => {
        localStorage.setItem('AuthKey', authKey);
        this.isLoggedIn = true;
      })
    );
  }

  logout(): void {
    localStorage.removeItem('AuthKey');
    this.isLoggedIn = false;
  }
}
