import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MembersComponent } from './members/members.component';
import { AuthGuard } from '../auth/auth.guard';
import { ProductsComponent } from './products/products.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { KassaComponent } from 'src/app/kassa/kassa.component';


const kassaRoutes: Routes = [
  {
    path: 'kassa',
    canActivate: [AuthGuard],
    component: KassaComponent,
    children: [
      {
        path: 'products',
        component: ProductsComponent
      },
      {
        path: 'checkout',
        component: CheckoutComponent
      },
      {
        path: '',
        component: MembersComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(kassaRoutes)],
  exports: [RouterModule]
})
export class KassaRoutingModule { }
