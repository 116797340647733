import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';

import { AppRoutingModule } from './app-routing.module';

import { TopBarComponent } from './top-bar/top-bar.component';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { HttpErrorHandlerService } from './services/httperrorhandler.service';
import { MessageService } from './services/message.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { KassaModule } from './kassa/kassa.module';
import { AdminModule } from './admin/admin.module';
import { LoginComponent } from './login/login.component';

import { registerLocaleData } from '@angular/common';
import localeNL from '@angular/common/locales/nl';

registerLocaleData(localeNL, 'nl');

@NgModule({
  declarations: [
    AppComponent,
    TopBarComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    KassaModule,
    AdminModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule
  ],
  providers: [
    HttpErrorHandlerService,
    MessageService,
    {
      provide: LOCALE_ID,
      useValue: 'nl'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
