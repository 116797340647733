import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HandleError, HttpErrorHandlerService } from './httperrorhandler.service';
import { environment } from 'src/environments/environment';
import { Order } from 'src/app/interfaces/order';
import { OrderProduct } from 'src/app/interfaces/orderproduct';
import { GuidService } from './guid.service';
import { Member } from '../interfaces/member';
import { Product } from '../interfaces/product';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'x-ms-blob-type': 'BlockBlob'
  })
};

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private readonly handleError: HandleError;
  url = environment.blobUrl + '/' + environment.blobContainer + '/';
  items = [];
  member: Member;
  orderId: string;

  constructor(
    private http: HttpClient,
    private httpErrorHandler: HttpErrorHandlerService,
    private guidService: GuidService) {
    this.handleError = httpErrorHandler.createHandleError('CartService');
  }

  InitializeCard(member: Member) {
    this.member = member;
    this.orderId = this.guidService.newGuid();
  }

  AddToCart(products) {
    this.items = [];
    for (const product of products) {
      if (product.quantity > 0) {
        this.items.push(product);
      }
    }
  }

  SendCartToServer(): Observable<any> {
    if (!this.orderId) {
      this.orderId = this.guidService.newGuid();
    }

    const itemList: OrderProduct[] = [];
    for (const item of this.items) {
      if (item.quantity > 0) {
        itemList.push(
          {
            product: item.name,
            amount: item.quantity,
            totalPrice: ''
          }
        );
      }
    }
    const order: Order = {
      memberName: this.member.name,
      memberId: this.member.memberId,
      orderProductList: itemList,
      created: new Date().toISOString()
    };

    const putUrl = this.url + this.orderId + '.json';

    return this.http.put<Order>(putUrl + environment.orderWriteBlobSAS, order, httpOptions);
  }

  GetName() {
    if (this.member) {
      return this.member.name;
    }
    return '';
  }

  GetItems(): any[] {
    if (this.items) {
      return this.items;
    }
    return [];
  }

  ClearCart() {
    this.items = [];
    this.member = null;
    this.orderId = null;
    return this.items;
  }

  GetProducts(): Observable<Product[]> {
    return this.http.get<Product[]>('/assets/products.json');
  }
}
