import { Component, OnInit } from '@angular/core';
import { AdminApiService } from 'src/app/services/admin-api.service';
import { EmailLog } from 'src/app/interfaces/email-log';

@Component({
  selector: 'app-email-logs',
  templateUrl: './email-logs.component.html',
  styleUrl: './email-logs.component.css'
})
export class EmailLogsComponent implements OnInit {
  public emailLogs: EmailLog[];
  public searchName = '';

  page = 0;
  pageSize = 25;

  constructor(private adminApiService: AdminApiService) {
  }

  ngOnInit() {
    this.adminApiService.GetEmailLogs(this.page, this.pageSize).subscribe(data => this.emailLogs = data);
  }

  nextPage() {
    if (this.emailLogs.length < this.pageSize) {
      return;
    }
    this.page++;
    this.adminApiService.GetEmailLogs(this.page, this.pageSize).subscribe(data => this.emailLogs = data);
  }

  previousPage() {
    if (this.page === 0) {
      return;
    }
    this.page--;
    this.adminApiService.GetEmailLogs(this.page, this.pageSize).subscribe(data => this.emailLogs = data);
  }
}
