<div>
  <table>
    <tr>
      <th>Datum</th>
      <th>Product</th>
      <th>Aantal</th>
    </tr>
    <tr *ngFor="let o of orders">
      <td>{{ o.orderDate | customDatePipe }} </td>
      <td>{{ o.name }}</td>
      <td>{{ o.quantity }}</td>
    </tr>
  </table>
</div>
