<div>
  <a (click)="Export()">Download Excel</a>
  <table id="order-table">
    <tr>
      <th>#</th>
      <th>Datum</th>
      <th>Naam</th>
      <th>Totaal</th>
      <th>Bestelling</th>
    </tr>
    <tr *ngFor="let o of orders">
      <td>{{ o.orderId }}</td>
      <td>{{ o.created | customDatePipe }} </td>
      <td>{{ o.memberName }}</td>
      <td>€ {{ o.orderTotalPrice}}</td>
      <td>
        <ul *ngFor="let orderProduct of o.orderProductList">
          <li>{{ orderProduct.amount }} {{ orderProduct.product }} - € {{ orderProduct.totalPrice }}</li>
        </ul>
      </td>
    </tr>
  </table>
</div>
