import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';
import { Product } from 'src/app/interfaces/product';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit, OnDestroy {
  products: Product[];

  constructor(
    private router: Router,
    public cartService: CartService,
    private matSnackBar: MatSnackBar) { }

  ngOnInit() {
    this.cartService.GetProducts()
      .subscribe(data => this.products = data);
  }

  ngOnDestroy() {
    this.matSnackBar.dismiss();
  }

  ClearCart() {
    this.cartService.ClearCart();
    this.router.navigate(['/kassa']).then();
  }

  CheckOut() {
    this.cartService.AddToCart(this.products);
    if (this.cartService.items.length === 0) {
      this.matSnackBar.open('Geen producten geselecteerd.', 'Sluiten');
      return;
    }
    this.router.navigate(['/kassa/checkout']).then();
  }
}
