<h2>KASSA / Producten - {{ cartService.member.name }}</h2>

<div class="product-container">
  <div *ngFor="let product of products" class="product-box">
    <div class="product-item-box">
      <img src="./assets/img/{{ product.image }}" alt="{{ product.name }}">
      <h3>{{ product.name }}</h3>
      <div class="product-quantity-circle">{{ product.quantity }}</div>
    </div>
    <div>
      <button (click)="product.quantity=product.quantity+1;" class="button-round-primary"> +1 </button>
      <button (click)="product.quantity=product.quantity+5;" class="button-round-primary"> +5 </button>
      <button (click)="product.quantity=product.quantity-1;" *ngIf="product.quantity > 0" class="button-round-secondary">-</button>
      <button *ngIf="product.quantity < 1" class="button-round-secondary">-</button>
    </div>
  </div>
  <div class="fixed-bottom-button">
    <button class="button cancel-button" (click)="ClearCart()">ANNULEREN</button>
    <a (click)="CheckOut()" class="button checkout-button"><i class="material-icons">shopping_cart</i>BEVESTIGEN</a>
  </div>
</div>
