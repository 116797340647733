<h2>LOGIN</h2>

<div class="login-box">
  <p>Status: {{message}}</p>
  <p *ngIf="!authService.isLoggedIn">
    <input type="password" class="login" [(ngModel)]="authKey" />
  </p>
  <p *ngIf="!authService.isLoggedIn">
    <button (click)="login()" class="login-button">Login</button>
  </p>
  <p *ngIf="authService.isLoggedIn">
    <button (click)="logout()" class="login-button">Logout</button>
  </p>
</div>
