import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KassaRoutingModule } from './kassa-routing.module';

import { CheckoutComponent } from './checkout/checkout.component';
import { MembersComponent } from './members/members.component';
import { ProductsComponent } from './products/products.component';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CustomPipeModule } from 'src/app/custom-pipe/custom-pipe.module';
import { KassaComponent } from 'src/app/kassa/kassa.component';
import { LoaderComponent } from 'src/app/loader/loader.component';

@NgModule({
  declarations: [
    KassaComponent,
    LoaderComponent,
    CheckoutComponent,
    MembersComponent,
    ProductsComponent
  ],
  imports: [
    CommonModule,
    KassaRoutingModule,
    FormsModule,
    CustomPipeModule,
    MatProgressSpinnerModule
  ],
  bootstrap: [KassaComponent]
})
export class KassaModule {
}
