<div>
  <table class="table">
    <tr>
      <th>Product</th>
      <th>Prijs</th>
    </tr>
    <tr *ngFor="let p of productPrices">
      <td>{{ p.name }}</td>
      <td>{{ p.price | currency:'EUR' }}</td>
    </tr>
  </table>
</div>
