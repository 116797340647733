import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CartService } from 'src/app/services/cart.service';
import { Member } from 'src/app/interfaces/member';

@Component({
  selector: 'app-cart',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit, OnDestroy {
  items: any[];
  member: Member;

  constructor(
    private router: Router,
    private cartService: CartService,
    private matSnackBar: MatSnackBar
  ) {

  }

  ngOnInit() {
    this.items = this.cartService.GetItems();
    this.member = this.cartService.member;
  }

  ngOnDestroy() {
    this.matSnackBar.dismiss();
  }

  SendCart() {
    this.cartService.SendCartToServer().subscribe(
      () => {
        this.cartService.ClearCart();
        this.router.navigate(['/kassa']).then();
      },
      () => {
        this.matSnackBar.open('Fout bij versturen, probeer later opnieuw.', 'Sluiten');
      });
  }

  ClearCart() {
    this.cartService.ClearCart();
    this.router.navigate(['/kassa']).then();
  }
}
