import { Component, OnInit } from '@angular/core';
import { AdminApiService } from 'src/app/services/admin-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ExcelExportService } from 'src/app/services/excel-export.service';
import { LoadingService } from 'src/app/services/loading.service';
import { Member } from 'src/app/interfaces/member';
import { OrderDetail } from 'src/app/interfaces/order-detail';
import { Deposit } from 'src/app/interfaces/deposit';

@Component({
  selector: 'app-member-overview',
  templateUrl: './member-overview.component.html',
  styleUrl: './member-overview.component.css'
})
export class MemberOverviewComponent implements OnInit  {
  members: Member[];
  orders: OrderDetail[];
  searchName: string = '';

  constructor(private adminApiService: AdminApiService,
              private matSnackBar: MatSnackBar,
              private excelExport: ExcelExportService,
              private loading: LoadingService) { }

  ngOnInit() {
    this.updateMembers();
    this.updateOrders();
  }

  updateMembers() {
    this.loading.show();
    this.adminApiService.GetMembers()
      .subscribe(data => {
        this.members = data;
        this.loading.hide();
      });
  }

  updateOrders() {
    this.adminApiService.GetOrders()
      .subscribe(data => this.orders = data);
  }

  postBalance(memberId: string) {
    const value = ((document.getElementById(memberId) as HTMLInputElement)).value;
    if (value !== '') {
      const deposit: Deposit = {
        memberId: Number(memberId),
        memberName: null,
        deposit: value,
        paymentStatus: null,
        paymentId: null,
        created: ''
      };
      this.adminApiService.PostDeposit(deposit).subscribe(() => {
        ((document.getElementById(memberId) as HTMLInputElement)).value = '';
        this.matSnackBar.open('Succesvol opgeslagen', 'Sluiten');

        // Update Members
        this.updateMembers();
      });
    }
  }

  Export(): void {
    this.excelExport.exportexcel('balance-table', 'balans');
  }
}
