import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip
} from 'ng-apexcharts';
import { AdminApiService } from 'src/app/services/admin-api.service';
import { DepositChart } from 'src/app/interfaces/deposit-chart';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
};

@Component({
  selector: 'app-balance-chart',
  templateUrl: './balance-chart.component.html',
  styleUrls: ['./balance-chart.component.css']
})
export class BalanceChartComponent implements OnInit, OnChanges {
  @Input () public yearMonth: { year: number | null , month: number | null };

  @ViewChild('chart') chart: ChartComponent;

  public chartOptions: Partial<ChartOptions>;
  public chartData: DepositChart[];

  public totalDeposit: number;
  public totalOrders: number;
  public countOrders: number;

  constructor(private adminApiService: AdminApiService) { }

  ngOnInit() {
    this.adminApiService.GetDepositChart().subscribe(next => {
      this.chartData = next;
      const chartData = this.generateChartData(next);
      this.setChart(chartData.series, chartData.categories);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.chartData === undefined) return;
    const chartData = this.generateChartData(this.chartData);
    this.setChart(chartData.series, chartData.categories);
  }

  private generateChartData(input: DepositChart[]): { series: ApexAxisChartSeries, categories: any } {
    let months: string[] = ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sept', 'okt', 'nov', 'dec'];
    if (this.yearMonth?.month != null) {
      console.log('SELECTED MONTH');
      months = [ months[this.yearMonth?.month - 1] ];
    }
    const years = input.map(s => s.year).filter((value, index, self) => self.indexOf(value) === index);
    const seriesPerYear = [];

    let totalDeposit = 0.00;
    let totalOrders = 0.00;
    let countOrders = 0;

    years.forEach(year => {
      if (this.yearMonth?.year && this.yearMonth?.year !== year) {
        return;
      }
      let dataFiltered = input.filter(s => s.year === year);
      if (this.yearMonth?.month != null) {
        dataFiltered = dataFiltered.filter(s => s.month === this.yearMonth?.month);
      }
      seriesPerYear.push({
        name: `bestellingen ${year}`,
        data: this.getSeries('orders', this.yearMonth?.month, dataFiltered)
      });
      seriesPerYear.push({
        name: `stortingen ${year}`,
        data: this.getSeries('deposit', this.yearMonth?.month, dataFiltered)
      });
      dataFiltered.forEach(item => {
        if (item.type === 'orders') {
          totalOrders += item.totalPrice;
          countOrders += item.count;
        }
        if (item.type === 'deposit') {
          totalDeposit += item.totalPrice;
        }
      });
    });

    this.countOrders = countOrders;
    this.totalDeposit = totalDeposit;
    this.totalOrders = totalOrders;

    return {
      series: seriesPerYear,
      categories: months
    };
  }

  private getSeries(inputType: string, month: number, input: DepositChart[]): number[] {
    const numbers = [];
    if (this.yearMonth?.month) {
      numbers.push(input.find(s => s.type === inputType && s.month === month)?.totalPrice ?? 0.00);
    } else {
      for (let i = 1; i < 13 + 1; i++) {
        numbers.push(input.find(s => s.type === inputType && s.month === i)?.totalPrice ?? 0.00);
      }
    }

    return numbers;
  }

  private setChart(series: ApexAxisChartSeries, categories: any): void {
    this.chartOptions = {
      series,
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%'
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories
      },
      yaxis: {
        title: {
          text: '€ (besteld)'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter(val) {
            return '€ ' + val + ' ';
          }
        }
      }
    };
  }
}
