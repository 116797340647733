import { Component, OnInit } from '@angular/core';
import { AdminApiService } from '../../services/admin-api.service';
import { OrderDetail } from 'src/app/interfaces/order-detail';
import { ExcelExportService } from '../../services/excel-export.service';

@Component({
  selector: 'app-order-overview',
  templateUrl: './order-overview.component.html',
  styleUrls: ['./order-overview.component.css']
})
export class OrderOverviewComponent implements OnInit {
  orders: OrderDetail[];

  constructor(private adminApiService: AdminApiService,
              private excelExport: ExcelExportService) { }

  ngOnInit(): void {
    this.adminApiService.GetOrdersSortedByCreated('desc')
      .subscribe(data => this.orders = data);
  }

  Export(): void {
    this.excelExport.exportexcel('order-table', 'bestellingen');
  }
}
