import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { AuthAdminService } from './auth-admin.service';

@Injectable({
  providedIn: 'root'
})
export class AuthAdminGuard  {
  constructor(private authAdminService: AuthAdminService,
              private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): true|UrlTree {
    const url: string = state.url;

    return this.checkAdminLogin(url);
  }

  checkAdminLogin(url: string): true|UrlTree {
    if (this.authAdminService.isAdminLoggedIn) { return true; }

    // Store the attempted URL for redirecting
    this.authAdminService.redirectUrl = url;

    // Navigate to the adminlogin page with extras
    return this.router.parseUrl('/adminlogin');
  }

}
