import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BalanceComponent } from './balance/balance.component';
import { DepositdetailComponent } from './depositdetail/depositdetail.component';
import { OrderdetailComponent } from './orderdetail/orderdetail.component';
import { AuthAdminGuard } from '../auth/auth-admin.guard';
import { ProductpricesComponent } from './productprices/productprices.component';
import { DepositsComponent } from './deposits/deposits.component';
import { OrderOverviewComponent } from './order-overview/order-overview.component';
import { OrderQuantityComponent } from './order-quantity/order-quantity.component';
import { AdminComponent } from 'src/app/admin/admin.component';
import { MemberOverviewComponent } from 'src/app/admin/member-overview/member-overview.component';
import { ContributionComponent } from 'src/app/admin/contribution/contribution.component';
import { EmailLogsComponent } from 'src/app/admin/email-logs/email-logs.component';


const routes: Routes = [
  {
    path: 'admin',
    canActivate: [AuthAdminGuard],
    component: AdminComponent,
    children: [
      {
        path: 'depositdetail/:id',
        component: DepositdetailComponent
      },
      {
        path: 'orderdetail/:id',
        component: OrderdetailComponent
      },
      {
        path: 'productprices',
        component: ProductpricesComponent
      },
      {
        path: 'orders',
        component: OrderOverviewComponent
      },
      {
        path: 'orders/quantity',
        component: OrderQuantityComponent
      },
      {
        path: 'deposits',
        component: DepositsComponent
      },
      {
        path: 'members',
        component: MemberOverviewComponent
      },
      {
        path: 'contribution',
        component: ContributionComponent
      },
      {
        path: 'emaillogs',
        component: EmailLogsComponent
      },
      {
        path: '',
        component: BalanceComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
