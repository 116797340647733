import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'customDatePipe'
})
export class CustomDatePipePipe implements PipeTransform {

  transform(date: string, args?: any): string {
    const inputDate = new Date(date);
    return new DatePipe('nl').transform(inputDate, 'EEEE d MMMM y, HH:mm');
  }

}
