import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { HttpErrorHandlerService } from './httperrorhandler.service';
import { AuthAdminService } from '../auth/auth-admin.service';

import { Member } from '../interfaces/member';
import { Productprice } from '../interfaces/productprice';
import { ProductOrder } from '../interfaces/product-order';
import { OrderDetail } from '../interfaces/order-detail';
import { Deposit } from '../interfaces/deposit';
import { DepositChart } from '../interfaces/deposit-chart';
import { Contribution } from 'src/app/interfaces/contribution';
import { EmailLog } from 'src/app/interfaces/email-log';

@Injectable({
  providedIn: 'root'
})
export class AdminApiService {
  getMembersUrl = environment.apiBaseUrl + '/api/GetMembers';
  getOrdersUrl = environment.apiBaseUrl + '/api/GetOrders';
  getProductOrdersUrl = environment.apiBaseUrl + '/api/GetProductOrders';
  getDepositUrl = environment.apiBaseUrl + '/api/GetDeposit';
  getDepositChartUrl = environment.apiBaseUrl + '/api/GetDepositChart';
  postDepositUrl = environment.apiBaseUrl + '/api/PostDeposit';
  getProductpricesUrl = environment.apiBaseUrl + '/api/GetProductPrices';
  getContributionUrl = environment.apiBaseUrl + '/api/contribution';
  getEmailLogsUrl = environment.apiBaseUrl + '/api/emaillogs';

  constructor(private http: HttpClient,
              private httpErrorHandler: HttpErrorHandlerService,
              private authAdminService: AuthAdminService) { }

  /* GET Members Endpoint */
  GetMembers(): Observable<Member[]> {
    const httpQueryParams = '?code=' + this.authAdminService.getAdminKey();
    return this.http.get<Member[]>(this.getMembersUrl + httpQueryParams)
      .pipe(
        retry(1),
        catchError(this.httpErrorHandler
          .handleError('AdminApiService', 'GetMembers', null))
      );
  }

  /* GET Members Endpoint */
  GetMembersByYearMonth(year: number, month: number | null = null): Observable<Member[]> {
    let httpQueryParams = '?code=' + this.authAdminService.getAdminKey() + '&year=' + year;
    if (month !== null) {
      httpQueryParams += '&month=' + month;
    }
    return this.http.get<Member[]>(this.getMembersUrl + httpQueryParams)
      .pipe(
        retry(1),
        catchError(this.httpErrorHandler
          .handleError('AdminApiService', 'GetMembers', null))
      );
  }

  /* GET Product Prices Endpoint */
  GetProductPrices(): Observable<Productprice[]> {
    const httpQueryParams = '?code=' + this.authAdminService.getAdminKey();
    return this.http.get<Productprice[]>(this.getProductpricesUrl + httpQueryParams)
      .pipe(
        retry(1),
        catchError(this.httpErrorHandler
          .handleError('AdminApiService', 'GetProductPrices', null))
      );
  }

  /* GET Orders Endpoint */
  GetOrders(): Observable<OrderDetail[]> {
    const httpQueryParams = '?code=' + this.authAdminService.getAdminKey();
    return this.http.get<OrderDetail[]>(this.getOrdersUrl + httpQueryParams)
      .pipe(
        retry(1),
        catchError(this.httpErrorHandler
          .handleError('AdminApiService', 'GetOrders', null))
      );
  }

  GetOrdersSortedByCreated(sortOrder: string): Observable<OrderDetail[]> {
    const httpQueryParams = '?sort=' + sortOrder + '&code=' + this.authAdminService.getAdminKey();
    return this.http.get<OrderDetail[]>(this.getOrdersUrl + httpQueryParams)
      .pipe(
        retry(1),
        catchError(this.httpErrorHandler
          .handleError('AdminApiService', 'GetOrders', null))
      );
  }

  GetOrdersByMemberId(memberId: string): Observable<OrderDetail[]> {
    let httpQueryParams = '?code=' + this.authAdminService.getAdminKey();
    httpQueryParams += '&memberId=' + memberId;
    httpQueryParams += '&sort=desc';
    return this.http.get<OrderDetail[]>(this.getOrdersUrl + httpQueryParams)
      .pipe(
        retry(1),
        catchError(this.httpErrorHandler
          .handleError('AdminApiService', 'GetOrders', null))
      );
  }

  /* GET Product Orders Endpoint */
  GetProductOrders(): Observable<ProductOrder[]> {
    const httpQueryParams = '?code=' + this.authAdminService.getAdminKey();
    return this.http.get<ProductOrder[]>(this.getProductOrdersUrl + httpQueryParams)
      .pipe(
        retry(1),
        catchError(this.httpErrorHandler
          .handleError('AdminApiService', 'GetProductOrders', null))
      );
  }

  /* GET Deposit Endpoint */
  GetDeposit(): Observable<Deposit[]> {
    const httpQueryParams = '?code=' + this.authAdminService.getAdminKey();
    return this.http.get<Deposit[]>(this.getDepositUrl + httpQueryParams)
      .pipe(
        retry(1),
        catchError(this.httpErrorHandler
          .handleError('AdminApiService', 'GetDeposit', null))
      );
  }

  GetDepositByMemberId(memberId: string): Observable<Deposit[]> {
    let httpQueryParams = '?code=' + this.authAdminService.getAdminKey();
    httpQueryParams += '&memberId=' + memberId;
    return this.http.get<Deposit[]>(this.getDepositUrl + httpQueryParams)
      .pipe(
        retry(1),
        catchError(this.httpErrorHandler
          .handleError('AdminApiService', 'GetDeposit', null))
      );
  }

  /* GET Deposit Chart Endpoint */
  GetDepositChart(): Observable<DepositChart[]> {
    const httpQueryParams = '?code=' + this.authAdminService.getAdminKey();
    return this.http.get<DepositChart[]>(this.getDepositChartUrl + httpQueryParams)
      .pipe(
        retry(1),
        catchError(this.httpErrorHandler
          .handleError('AdminApiService', 'GetDeposit', null))
      );
  }

  /* POST Deposit Endpoint */
  PostDeposit(deposit: Deposit): Observable<any> {
    const httpQueryParams = '?code=' + this.authAdminService.getAdminKey();
    return this.http.post(this.postDepositUrl + httpQueryParams, deposit)
      .pipe(
        retry(0),
        catchError(this.httpErrorHandler
          .handleError('AdminApiService', 'PostDeposit', deposit))
      );
  }

  /* GET Contribution Endpoint */
  GetContribution(): Observable<Contribution[]> {
    const httpQueryParams = '?code=' + this.authAdminService.getAdminKey();
    return this.http.get<Contribution[]>(this.getContributionUrl + httpQueryParams)
      .pipe(
        retry(1),
        catchError(this.httpErrorHandler
          .handleError('AdminApiService', 'GetContribution', null))
      );
  }

  /* GET Contribution Endpoint */
  SendContributionEmail(email: string): Observable<any> {
    const httpQueryParams = '?code=' + this.authAdminService.getAdminKey();
    const body = { email };
    return this.http.post<any>(this.getContributionUrl + '/email' + httpQueryParams, body)
      .pipe(
        retry(0),
        catchError(this.httpErrorHandler
          .handleError('AdminApiService', 'GetContributionEmail', null))
      );
  }

  /* POST Contribution Paid endpoint */
  GetContributionPaid(id: number): Observable<any> {
    const httpQueryParams = '?code=' + this.authAdminService.getAdminKey();
    return this.http.get(this.getContributionUrl + '/paid/' + id + httpQueryParams)
      .pipe(
        retry(0),
        catchError(this.httpErrorHandler
          .handleError('AdminApiService', 'GetContributionPaid'))
      );
  }

  /* GET Email Logs Endpoint */
  GetEmailLogs(page: number, pageSize: number): Observable<EmailLog[]> {
    let httpQueryParams = '?code=' + this.authAdminService.getAdminKey();
    httpQueryParams += '&page=' + page;
    httpQueryParams += '&pageSize=' + pageSize;
    return this.http.get<EmailLog[]>(this.getEmailLogsUrl + httpQueryParams)
      .pipe(
        retry(1),
        catchError(this.httpErrorHandler
          .handleError('AdminApiService', 'GetEmailLogs', null))
      );
  }
}
