import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthAdminService } from '../auth/auth-admin.service';

@Component({
  templateUrl: './adminlogin.component.html',
  styleUrls: ['./adminlogin.component.css']
})
export class AdminloginComponent implements OnInit {
  message: string;
  authKey: string;

  constructor(
    public authAdminService: AuthAdminService,
    public router: Router) {
    this.setMessage();
  }

  ngOnInit(): void {
    if (localStorage.getItem('AuthKeyAdmin')) {
      console.log('authkey admin found');
      this.authKey = localStorage.getItem('AuthKeyAdmin');
      // this.loginAdmin();
    }
  }

  setMessage() {
    this.message = 'Logged ' + (this.authAdminService.isAdminLoggedIn ? 'in' : 'out');
  }

  loginAdmin() {
    this.message = 'Trying to log in ...';

    this.authAdminService.loginAdmin(this.authKey).subscribe(() => {
      this.setMessage();
      if (this.authAdminService.isAdminLoggedIn) {
        // Usually you would use the redirect URL from the auth service.
        // However to keep the example simple, we will always redirect to `/admin`.
        const redirectUrl = '/admin';

        // Redirect the user
        this.router.navigate([redirectUrl]);
      }
    });
  }

  logoutAdmin() {
    this.authAdminService.logoutAdmin();
    this.authKey = null;
    this.setMessage();
  }

}
