import { Component, OnInit } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { Member } from 'src/app/interfaces/member';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.css']
})
export class MembersComponent implements OnInit {
  members: Member[];
  searchName = '';

  constructor(private apiService: ApiService,
              private cartService: CartService) { }

  ngOnInit() {
    this.getMembers();
  }

  getMembers(){
    this.apiService.GetMembers()
      .subscribe(data => this.members = data.filter(m => m.active === true));
  }

  addNameToCart(member: Member) {
    this.cartService.InitializeCard(member);
  }
}
