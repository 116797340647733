// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiBaseUrl: 'https://northseasurfing.azurewebsites.net',
  memberBaseUrl: 'https://northseasurfing.blob.core.windows.net/members/members.json',
  blobUrl: 'https://northseasurfing.blob.core.windows.net',
  blobContainer: 'incoming',
  orderWriteBlobSAS: '?sv=2022-11-02&ss=b&srt=o&sp=w&se=2027-05-31T22:00:00Z&st=2023-05-30T22:00:00Z&spr=https&sig=T4d%2BoQ8k43tRCv2c1nkuyCBkP81MATazTPQbIn26RKY%3D',
  memberReadBlobSAS: '?sp=r&st=2022-08-01T19:55:19Z&se=2024-08-01T03:55:19Z&spr=https&sv=2021-06-08&sr=c&sig=7Ru1UC%2FOglZVVwLWq%2F3Kr3Iil7gtIlpLNrcJV8SajWI%3D'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
