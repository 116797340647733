import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Member } from 'src/app/interfaces/member';
import { catchError, map, retry } from 'rxjs/operators';
import { HttpErrorHandlerService } from './httperrorhandler.service';
import { AuthService } from 'src/app/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  memberBlobUrl = environment.memberBaseUrl + environment.memberReadBlobSAS;
  memberApiUrl = environment.apiBaseUrl + '/api/GetMembers';

  members: Member[];
  membersObservable: Observable<Member[]>;

  constructor(private http: HttpClient,
              private httpErrorHandler: HttpErrorHandlerService,
              private authService: AuthService) { }

  GetMembers(): Observable<Member[]> {
    if (this.members) {
      return of(this.members);
    }

    return this.GetMembersFromApi().pipe(
      catchError(() => {
        this.httpErrorHandler
          .handleError('ApiService', 'GetMembers', null);
        return this.GetMembersFromBlob();
      })
    );
  }

  private GetMembersFromApi(): Observable<Member[]> {
    return this.http.get<Member[]>(this.memberApiUrl + '?code=' + this.authService.getKey())
      .pipe(
        map(resp => {
          this.members = resp;
          return resp;
        }),
        retry(1)
      );
  }

  private GetMembersFromBlob(): Observable<Member[]> {
    return this.http.get<Member[]>(this.memberBlobUrl)
      .pipe(
        map(resp => {
          this.members = resp;
          return resp;
        }),
        retry(1)
      );
  }
}
