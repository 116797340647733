import { Component, OnInit } from '@angular/core';
import { Deposit } from '../../interfaces/deposit';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminApiService } from '../../services/admin-api.service';

@Component({
  selector: 'app-deposits',
  templateUrl: './deposits.component.html',
  styleUrls: ['./deposits.component.css']
})
export class DepositsComponent implements OnInit {
  deposit: Deposit[];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private adminApiService: AdminApiService) { }

  ngOnInit() {
    this.adminApiService.GetDeposit().subscribe(
      data => this.deposit = data
    );
  }

}
