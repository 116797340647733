<mat-grid-list cols="3" rowHeight="50px">
  <mat-grid-tile>Totaal Opgenomen</mat-grid-tile>
  <mat-grid-tile>Totaal Gestort</mat-grid-tile>
  <mat-grid-tile>Totaal Bestellingen</mat-grid-tile>
  <mat-grid-tile>
    <h1>{{ totalOrders | currency:'EUR' }}</h1>
  </mat-grid-tile>
  <mat-grid-tile>
    <h1>{{ totalDeposit | currency:'EUR'  }}</h1>
  </mat-grid-tile>
  <mat-grid-tile>
    <h1>{{ countOrders }}</h1>
  </mat-grid-tile>
</mat-grid-list>

<div *ngIf="chartOptions" id="chart">
  <apx-chart
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [dataLabels]="chartOptions.dataLabels"
    [plotOptions]="chartOptions.plotOptions"
    [yaxis]="chartOptions.yaxis"
    [legend]="chartOptions.legend"
    [fill]="chartOptions.fill"
    [stroke]="chartOptions.stroke"
    [tooltip]="chartOptions.tooltip"
    [xaxis]="chartOptions.xaxis"
  ></apx-chart>
</div>
