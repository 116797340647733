<div>
  <mat-form-field>
    <mat-label>Filter Jaar</mat-label>
    <mat-select (selectionChange)="selectYear($event.value)">
      <mat-option [value]="null">Alle Jaren</mat-option>
      @for (year of years; track year) {
        <mat-option [value]="year">{{year}}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="yearMonthSelected | async">
    <mat-label>Filter Maand</mat-label>
    <mat-select (selectionChange)="selectMonth($event.value)">
      <mat-option [value]="null">Alle Maanden</mat-option>
      @for (month of [1,2,3,4,5,6,7,8,9,10,11,12]; track month) {
        <mat-option [value]="month">{{ months[month -1] }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>

<div>
  <app-balance-chart [yearMonth]="yearMonthSelected | async">
  </app-balance-chart>
</div>

<div>
  <table id="balance-table">
    <tr>
      <th>#</th>
      <th>Naam</th>
      <th>Besteld</th>
      <th>Gestort</th>
      <th>Opgenomen</th>
      <th></th>
    </tr>
    <tr *ngFor="let m of members">
      <td>{{ m.memberId }} </td>
      <td>{{ m.name }} <span *ngIf="!m.active">(inactief)</span></td>
      <td> {{ m.amountOrders }}</td>
      <td>{{ m.deposit | currency:'EUR' }} <a [routerLink]="['/admin/depositdetail', m.memberId]">(detail)</a></td>
      <td>{{ m.withdraw | currency:'EUR' }} <a [routerLink]="['/admin/orderdetail', m.memberId]">(detail)</a></td>
    </tr>
  </table>
</div>
