import { Component, OnInit } from '@angular/core';
import { AdminApiService } from '../../services/admin-api.service';
import { ProductOrder } from '../../interfaces/product-order';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-order-quantity',
  templateUrl: './order-quantity.component.html',
  styleUrls: ['./order-quantity.component.css']
})
export class OrderQuantityComponent implements OnInit {
  orders: ProductOrder[];

  constructor(private adminApiService: AdminApiService,
              private loader: LoadingService) { }

  ngOnInit(): void {
    this.adminApiService.GetProductOrders().subscribe(data => {
      this.orders = data;
    });
  }

}
