<div>
  <table>
    <tr>
      <th>#</th>
      <th>Datum</th>
      <th>Naam</th>
      <th>Totaal</th>
      <th>Bestelling</th>
    </tr>
    <tr *ngFor="let o of orders">
      <td>{{ o.orderId }}</td>
      <td>{{ o.created | customDatePipe }} </td>
      <td>{{ o.memberName }}</td>
      <td>€ {{ o.orderTotalPrice}}</td>
      <td>
        <div *ngFor="let ol of o.orderProductList">
          {{ ol.amount }}x {{ ol.product }} : {{ ol.totalPrice }}
        </div>
      </td>
    </tr>
  </table>
</div>
