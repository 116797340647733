import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { MessageService } from './message.service';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { AuthAdminService } from '../auth/auth-admin.service';

export type HandleError =
  <T> (operation?: string, result?: T) => (error: HttpErrorResponse) => Observable<T>;

@Injectable()
export class HttpErrorHandlerService {
  constructor(private messageService: MessageService,
              private router: Router,
              private authService: AuthService,
              private authAdminService: AuthAdminService) { }

  /** Create curried handleError function that already knows the service name */
  createHandleError = (serviceName = '') => <T>
  (operation = 'operation', result = {} as T) => this.handleError(serviceName, operation, result);

  /**
   * Returns a function that handles Http operation failures.
   * This error handler lets the app continue to run as if no error occurred.
   * @param serviceName = name of the data service that attempted the operation
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  handleError<T>(serviceName = '', operation = 'operation', result = {} as T) {
    return (error: HttpErrorResponse): Observable<T> => {
      console.error(error);

      const message = (error.error instanceof  ErrorEvent) ?
        error.error.message :
        `server returned code ${error.status} with body "${error.error}"`;

      this.messageService.add(`${serviceName}: ${operation} failed: ${message}`);

      if (error.status === 401) {
        console.log(serviceName);

        if (serviceName === 'AdminApiService') {
          this.authAdminService.logoutAdmin();
          this.router.navigate(['/adminlogin']);
        } else {
          this.authService.logout();
          this.router.navigate(['/login']);
        }
      }

      return of(result);
    };
  }
}
