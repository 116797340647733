import { Component } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-kassa',
  templateUrl: './kassa.component.html',
  styleUrl: './kassa.component.css'
})
export class KassaComponent {
  constructor(public loader: LoadingService) {}
}
