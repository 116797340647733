<h2>ADMIN LOGIN</h2>

<div class="login-box">
  <p>Status: {{message}}</p>
  <p *ngIf="!authAdminService.isAdminLoggedIn">
    <input type="password" class="login" [(ngModel)]="authKey" />
  </p>
  <p *ngIf="!authAdminService.isAdminLoggedIn">
    <button (click)="loginAdmin()" class="login-button">Login Admin</button>
  </p>
  <p *ngIf="authAdminService.isAdminLoggedIn">
    <button [routerLink]="['/admin']" class="login-button">GA NAAR ADMIN</button>
    <button (click)="logoutAdmin()" class="login-button">Logout Admin</button>
  </p>
</div>
