<div class="membersearch" style="text-align: center">
  <input class="search search-name-input" type="text" name="search" [(ngModel)]="searchName" autocomplete="off" placeholder="Zoek voor een naam">
</div>

<div>
  <a (click)="Export()">Download Excel</a>
  <table id="balance-table">
    <tr>
      <th>#</th>
      <th>Naam</th>
      <th>Besteld</th>
      <th>Gestort</th>
      <th>Opgenomen</th>
      <td>Saldo</td>
      <th></th>
    </tr>
    <tr *ngFor="let m of members | filter: searchName">
      <td>{{ m.memberId }} </td>
      <td>{{ m.name }} <span *ngIf="!m.active">(inactief)</span></td>
      <td> {{ m.amountOrders }}</td>
      <td>{{ m.deposit | currency:'EUR' }} <a [routerLink]="['/admin/depositdetail', m.memberId]">(detail)</a></td>
      <td>{{ m.withdraw | currency:'EUR' }} <a [routerLink]="['/admin/orderdetail', m.memberId]">(detail)</a></td>
      <td>{{ m.deposit - m.withdraw | currency:'EUR' }} </td>
      <td>
        <input id="{{m.memberId}}" type="number" min="0" max="1000" placeholder="0" />
        <button (click)="postBalance(m.memberId.toString())">bijstorten</button>
      </td>
    </tr>
  </table>
</div>
