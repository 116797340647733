<div class="membersearch" style="text-align: center">
  <input class="search search-name-input" type="text" name="search" [(ngModel)]="searchName" autocomplete="off" placeholder="Zoek voor een naam">
</div>

<div>
  <!--  <a (click)="Export()">Download Excel</a>-->
  <table id="balance-table">
    <tr>
      <th>#</th>
      <th>Datum</th>
      <th>Email</th>
      <th>Onderwerp</th>
      <th>Status</th>
      <th>Response</th>
    </tr>
    <tr *ngFor="let l of emailLogs | filter: searchName ">
      <td>{{ l.id }}</td>
      <td>{{ l.dateCreated | date:'dd-MM-yyyy HH:mm' }}</td>
      <td>{{ l.emailAddress }}</td>
      <td>{{ l.emailSubject }}</td>
      <td>{{ l.status }}</td>
      <td>{{ l.response }}</td>
    </tr>
  </table>
  <div>
    <button *ngIf="page > 0" (click)="previousPage()">Vorige</button>
    <button *ngIf="emailLogs.length == pageSize" (click)="nextPage()">Volgende</button>
  </div>
</div>
