import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { OrderdetailComponent } from './orderdetail/orderdetail.component';
import { BalanceComponent } from './balance/balance.component';
import { DepositdetailComponent } from './depositdetail/depositdetail.component';
import { FormsModule } from '@angular/forms';
import { AdminloginComponent } from '../adminlogin/adminlogin.component';
import { ProductpricesComponent } from './productprices/productprices.component';
import { DepositsComponent } from './deposits/deposits.component';
import { OrderOverviewComponent } from './order-overview/order-overview.component';
import { OrderQuantityComponent } from './order-quantity/order-quantity.component';
import { CustomPipeModule } from 'src/app/custom-pipe/custom-pipe.module';
import { NgApexchartsModule } from 'ng-apexcharts';
import { BalanceChartComponent } from './balance-chart/balance-chart.component';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatGridListModule } from '@angular/material/grid-list';
import { MemberOverviewComponent } from 'src/app/admin/member-overview/member-overview.component';
import { AdminComponent } from 'src/app/admin/admin.component';
import { MatButtonModule } from '@angular/material/button';
import { ContributionComponent } from 'src/app/admin/contribution/contribution.component';
import { EmailLogsComponent } from 'src/app/admin/email-logs/email-logs.component';

@NgModule({
  declarations: [
    AdminComponent,
    OrderdetailComponent,
    BalanceComponent,
    DepositdetailComponent,
    AdminloginComponent,
    ProductpricesComponent,
    DepositsComponent,
    OrderOverviewComponent,
    OrderQuantityComponent,
    BalanceChartComponent,
    MemberOverviewComponent,
    ContributionComponent,
    EmailLogsComponent
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    FormsModule,
    CustomPipeModule,
    NgApexchartsModule,
    MatInputModule,
    MatDatepickerModule,
    MatSelectModule,
    MatGridListModule,
    MatButtonModule,
  ],
  bootstrap: [AdminComponent]
})
export class AdminModule { }
