<div>
  <table>
    <tr>
      <th>Datum</th>
      <th>Lid</th>
      <th>Storting</th>
      <th>Mollie Id</th>
      <th>Mollie Status</th>
    </tr>
    <tr *ngFor="let d of deposit">
      <td>{{ d.created | date }}</td>
      <td>{{ d.memberName }}</td>
      <td>{{ d.deposit | currency:'EUR' }}</td>
      <td>{{ d.paymentId }}</td>
      <td>{{ d.paymentStatus }}</td>
    </tr>
  </table>
</div>
