import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Deposit } from '../../interfaces/deposit';
import { AdminApiService } from '../../services/admin-api.service';

@Component({
  selector: 'app-depositdetail',
  templateUrl: './depositdetail.component.html',
  styleUrls: ['./depositdetail.component.css']
})
export class DepositdetailComponent implements OnInit {
  deposit: Deposit[];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private adminApiService: AdminApiService) { }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    this.adminApiService.GetDepositByMemberId(id).subscribe(
      data => this.deposit = data
    );
  }
}
