import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminApiService } from '../../services/admin-api.service';
import { OrderDetail } from 'src/app/interfaces/order-detail';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-orderdetail',
  templateUrl: './orderdetail.component.html',
  styleUrls: ['./orderdetail.component.css']
})
export class OrderdetailComponent implements OnInit {
  orders: OrderDetail[];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private adminApiService: AdminApiService,
              private loadingService: LoadingService) { }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    this.loadingService.show();
    this.adminApiService.GetOrdersByMemberId(id).subscribe(data => {
      this.orders = data;
      this.loadingService.hide();
    });
  }

}
