import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomDatePipePipe } from '../custom-pipe/custom-date-pipe.pipe';
import { FilterPipe } from 'src/app/custom-pipe/filter.pipe';

@NgModule({
  declarations: [
    CustomDatePipePipe,
    FilterPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CustomDatePipePipe,
    FilterPipe
  ],
})
export class CustomPipeModule { }
