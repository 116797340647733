import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.css'
})
export class AdminComponent {
  linkButtons  = [
    { link: '/admin', label: 'Overzicht', color: 'link' },
    { link: '/admin/members', label: 'Leden Overzicht', color: 'link'  },
    { link: '/admin/productprices', label: 'Prijzen', color: 'link'  },
    { link: '/admin/deposits', label: 'Alle stortingen', color: 'link'  },
    { link: '/admin/orders', label: 'Alle bestellingen', color: 'link'  },
    { link: '/admin/orders/quantity', label: 'Aantal bestellingen', color: 'link'  },
    { link: '/admin/contribution', label: 'Contributie', color: 'link'  },
    { link: '/admin/emaillogs', label: 'Email Log', color: 'link'  },
  ];

  constructor(public router: Router) {
  }
}
