<h2>KASSA / Leden</h2>

<div class="name-search-box">
  <input class="search name-seach-input" type="text" name="search" [(ngModel)]="searchName" autocomplete="off" placeholder="Zoek voor een naam">
  <img (click)="getMembers()" class="refresh-box" src="assets/icons/refresh-black-24dp.svg" alt="" />
</div>

<div *ngIf="!members" class="spinner-container">
  <div class="spinner-box">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>
  <i>Bezig met ophalen van gegevens...</i>
</div>

<div class="name-button-container">
  <div *ngFor="let member of members | filter: searchName" class="name-button-box">
    <a [routerLink]="['/kassa/products']" (click)="addNameToCart(member)" class="button name-button">{{ member.name }}</a>
  </div>
</div>
